import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import ImageCardCarouselSection from 'components/Sections/ImageCardSections/CoronavirusHubCardGroupSection';
import ToolboxLayout from 'layouts/toolboxLayout';
import HeroSubscribeForm from 'components/Form/HeroSubscribeForm';
import ContentfulDynamicHero from 'components/Contentful/ContentfulDynamicHero';

const CoronavirusHub = (props) => {
  const blogs = props.data.allContentfulBlog?.edges;
  const contentHub = props.data.allContentfulContentHub?.edges[0]?.node;
  const dynamicHero = props.data.contentfulDynamicHero;
  const allContent = [...blogs];
  const hubType = 'coronavirus';

  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Coronavirus',
        icon: null,
        url: null,
      },
    ],
  };

  return (
    <ToolboxLayout
      breadcrumbs={breadcrumbs}
      contentfulSeo={contentHub.seo}
      layout="toolbox"
      {...props}
    >
      <div>
        <CovidDynamicHero
          {...dynamicHero}
          images={[]}
          customInlineForm={
            <HeroSubscribeForm revealProps={false} buttonColor="secondary" theme="light" />
          }
        />

        <ImageCardCarouselSection
          content={allContent}
          slug={contentHub.seo?.pageUrl}
          featuredContent={contentHub.featuredContent?.length > 0 && contentHub.featuredContent[0]}
          hubType={hubType}
          menuText="Category"
        />
      </div>
      <Helmet>
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          data-domain-script="018e7e31-fe89-7131-a3ab-879baeddc2cc-test"
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
        />
      </Helmet>
    </ToolboxLayout>
  );
};

export default CoronavirusHub;

export const pageQuery = graphql`
  query CoronavirusHubQuery {
    contentfulDynamicHero(internalName: { eq: "Dynamic Hero - Covid Hub" }) {
      ...DynamicHero
    }
    allContentfulBlog(
      sort: { fields: publishDate, order: DESC }
      filter: { tags: { eq: "Covid19" } }
    ) {
      edges {
        node {
          __typename
          id
          title
          seo {
            ...Seo
          }
          tags
          excerpt {
            excerpt
          }
          featuredImage {
            title
            description
            ...Image
          }
          publishDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
    allContentfulContentHub(filter: { tags: { eq: "Covid19" } }, limit: 1) {
      edges {
        node {
          seo {
            ...Seo
          }
          websiteHeader {
            logoRoute
            navigation {
              ... on ContentfulNavigation {
                navigation {
                  ... on ContentfulNavigationElement {
                    internalName
                    group {
                      group
                    }
                    icon {
                      ... on ContentfulImage {
                        alt
                        image {
                          file {
                            url
                          }
                        }
                      }
                    }
                    label
                    url
                    description
                    subNavigationElement {
                      ... on ContentfulNavigationElement {
                        ... on ContentfulNavigationElement {
                          internalName
                          group {
                            group
                          }
                          icon {
                            ... on ContentfulImage {
                              alt
                              image {
                                file {
                                  url
                                }
                              }
                            }
                          }
                          label
                          url
                          description
                        }
                      }
                    }
                  }
                }
              }
            }
            contextualCta {
              ... on ContentfulCallToAction {
                buttonLabel
                url
              }
              ...InlineMarketingForm
            }
          }
          heading
          subhead {
            subhead
          }
          emailSubscriptionForm {
            formTitle
            marketoForm {
              url
              name
            }
          }
          featuredContent {
            ... on ContentfulBlog {
              __typename
              id
              title
              seo {
                pageUrl
              }
              tags
              excerpt {
                excerpt
              }
              featuredImage {
                ...Image
              }
              publishDate(formatString: "MMMM Do, YYYY")
            }
          }
          websiteFooter {
            navigation {
              navigation {
                ... on ContentfulNavigationElement {
                  group {
                    group
                  }
                  label
                  icon {
                    ... on ContentfulImage {
                      alt
                      image {
                        file {
                          url
                        }
                      }
                    }
                  }
                  url
                  description
                  subNavigationElement {
                    ... on ContentfulNavigationElement {
                      group {
                        group
                      }
                      icon {
                        ... on ContentfulImage {
                          alt
                          image {
                            file {
                              url
                            }
                          }
                        }
                      }
                      label
                      url
                      description
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CovidDynamicHero = styled(ContentfulDynamicHero)`
  padding-top: 70px;
  .section-layout--inner-container {
    padding-top: 0px;
  }
  .hero-subscribe-form-container {
    margin-top: 40px;
    width: 100%;
    .data-layer-form {
      justify-content: center;
    }
  }
  .hero-copy {
    .hero-copy-description {
      font-size: var(--font-size-h5);
      margin: 50px auto 0px;
      padding: 0px 28px;
      color: var(--dark);
      line-height: var(--line-height-120);
    }
  }

  @media (max-width: 767px) {
    padding-top: 40px;
    .section-layout--inner-container {
      padding: 60px 0px 28px;
    }
    .hero-copy {
      margin-bottom: 0;
      .hero-copy-description {
        margin-top: 20px;
        font-size: var(--font-size-h5);
        padding: 0px;
      }
    }
  }
`;
