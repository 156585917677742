import React from 'react';
import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';
import ImageCardSliderSection from '../ImageCardSliderSection';
import ResourceHubFeaturedContent from '../ResourceHubFeaturedContent';
import './styles.less';

const ImageCardCarouselSection = ({
  background,
  menuText,
  title,
  content,
  hubType,
  featuredContent,
  ...otherProps
}) => {
  let categories = {
    Playbooks: [],
    'Most Popular': [],
    'News & Industry Trends': [],
    'Success Stories': [],
    'Webinar Recap': [],
  };

  content.forEach((ele) => {
    const { taxonomy } = ele.node.seo;
    const category = taxonomy ? [...(taxonomy.industries || []), ...(taxonomy.topics || []), ...(taxonomy.contentType || [])].join(', ') : [];
    if (category.includes('News') || category.includes('Industry Trends')) {
      categories['News & Industry Trends'].push(ele.node);
    } else if (category.includes('Playbook')) {
      categories['Playbooks'].push(ele.node);
    } else if (category.includes('Success Story')) {
      categories['Success Stories'].push(ele.node);
    } else if (category.includes('Webinar Recap')) {
      categories['Webinar Recap'].push(ele.node);
    } else {
      categories['Most Popular'].push(ele.node);
    }
  });

  return (
    <>
      <SectionLayout
        className="section-image-card-carousel--main-container"
        backgroundColor={'--titan-blue-1'}
      >
        <ResourceHubFeaturedSection>
          {featuredContent && !otherProps?.slug?.includes('/page/') && (
            <ResourceHubFeaturedContent featuredContent={featuredContent} />
          )}
        </ResourceHubFeaturedSection>
      </SectionLayout>

      {Object.keys(categories).map((category, index) => {
        return (
          categories[category].length > 0 && (
            <ImageCardSliderSection
              key={index}
              title={category}
              sectionsData={categories[category]}
              hubType={hubType}
              titleColor={'var(--darkest)'}
            />
          )
        );
      })}
    </>
  );
};

const ResourceHubFeaturedSection = styled.div`
  .section-blog-card--card-container {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  @media (max-width: 767px) {
    .featured-content-section {
      .side-card--container {
        .side-card--image-wrapper {
          width: 43%;
        }

        .side-card--card-wrapper {
          width: 57%;
        }
      }
    }
  }
`;

export default ImageCardCarouselSection;
